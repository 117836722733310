ngneat-dialog {
  .ngneat {
    &-dialog {
      &-backdrop {padding: 0; z-index: 100; overflow-y: auto; padding-top: 2rem; box-sizing: border-box;}
      &-content {position: relative; background-color: transparent; box-shadow: none; overflow: visible;
        opacity: 0; transform: translateY(100px);
        animation: reset 0.6s cubic-bezier(.25,.8,.25,1) forwards;
      }
    }
    &-close-dialog {display: none;}
    @media (max-width: $mobile) {
      &-dialog {
        &-backdrop {padding-top: 0;}
        &-content {flex-grow: 1; height: 100% !important; pointer-events: none;
          & > :last-child {display: contents;}
        }
      }
    }
  }
}
