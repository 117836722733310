@import "swiper/swiper-bundle.css";
@import "variables";

.swiper {z-index: 0; overflow: visible;
  &-wrapper {box-sizing: content-box; display: flex; height: 100%; position: relative;
    width: 100%; z-index: 1;
  }
  &-slide {height: 100%; max-height: 100%; max-width: 100%; overflow: auto;
    position: relative; flex-shrink: 0;
  }
  &-pagination {margin-top: 0.75rem;
    &-bullets {display: flex; gap: 10px; width: 100%; justify-content: center;}
    &-bullet {width: 8px; height: 8px; background: $color-pink; transition: background 0.4s, width 0.4s; border-radius: 100px;
      &-active {background: #EC89FC; width: 16px;}
    }
    &-lock {display: none;}
    &-primary {
      .swiper-pagination {
        &-bullet {background: $color-grays-light;
          &-active {background: $color-brand-primary;}
        }
      }
    }
    &-purple {
      .swiper-pagination {
        &-bullet {background: $color-grays-light;
          &-active {background: $color-purple;}
        }
      }
    }
  }
}

.swiper-scrollbar {
  background: #DEDEDE;
  height: 4px !important;
  width: 91% !important;
  left: 5% !important;

  &-drag {
    background-color: $color-purple; /* Match your variant color */
  }
}

