@keyframes skeleton-animation {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}

@keyframes skeleton-appearing {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.skeleton {
  background-image: linear-gradient(
    90deg,
    hsl(210, 15%, 88%),
    hsl(210, 15%, 95%),
    hsl(210, 15%, 88%)
  ) !important;
  background-size: 200% !important;
  animation: skeleton-animation 1s infinite reverse, skeleton-appearing 1s;
}
