.auth {padding: 7rem 0; position: relative; min-height: calc(100vh - var(--navbar-height)); box-sizing: border-box;
  &-form {position: relative; box-sizing: content-box; padding: 2.375rem 2rem 2rem; max-width: 464px; width: 100%;
    margin: 0 auto;
    &::before {content: ''; position: absolute; left: 0; top: 0; right: 0; bottom: 0; z-index: -2;
      background-color: $color-grays-white; border-radius: 1rem; box-shadow: $shadow-level-2;
    }
  }
  &-title {margin-bottom: 2rem;
    &-left {text-align: right;}
  }
  &-divider {position: relative; text-align: center; margin: 20px 0; color: $color-grays-black; font-size: 16px;
    &::before, &::after {content: ''; position: absolute; top: 0; bottom: 0;
      margin: auto 0; background: $color-divider; height: 1px;
    }
    &::before {left: 0px; right: calc(50% + 4rem);}
    &::after {right: 0px; left: calc(50% + 4rem);}
  }
  &-info {font-weight: 400; font-size: $font-size-body; line-height: 1.46em; color: $color-grays-dark;
    display: flex; align-items: center; height: 100%;
  }
  @media (max-width: $mobile) {padding: 0 0 20px;
    &-form {padding: 16px; box-sizing: border-box;
      &::before {display: none;}
    }
    &-title {margin-bottom: 24px;}
    &-divider {font-size: 12px;}
  }
}
