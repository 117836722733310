.form {
  &-title {color: $color-primary-text; text-align: center;}
  &-section {padding: 0.5rem 0;}
  &-button {margin-bottom: 14px;}
  &-control {background: $color-grays-white; border-radius: 16px; box-sizing: border-box; padding: 13px 19px;
    font: inherit; font-size: 14px; font-weight: 400; width: 100%; border: 1px solid $color-grays-border;
    line-height: 24px;
    &.invalid {border-color: rgba($color-status-error, 0.2);}
    &:hover {border-color: $color-pink;}
    &:focus {outline: none;}
    &::placeholder {color: $color-grays-silver; opacity: 1;}
    &:disabled {color: $color-grays-silver; pointer-events: none; background-color: $color-background;}
    &-icon {position: absolute; right: 10px; top: 0; bottom: 0;margin: auto 0; cursor: pointer; width: 24px; height: 24px;}
    &-textarea {
      resize: none; height: 100%; border: unset;
      &-wrapper {border: 1px solid $color-grays-border; border-radius: 16px; display: flex; overflow: hidden; height: 100%;}
    }
    &-tel {
      margin-left: 118px!important; padding-left: 19px!important; width: calc(100% - 118px);
      &-svg {
        position: absolute; left: 78px; top: 50%; transform: translateY(-50%); transition: 0.4s;
        &-active {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }
  }
  &-error {font-size: 12px; line-height: 140%; font-weight: 400; color: $color-status-error;
    margin-top: 4px; white-space: pre-line;
  }
  &-limit {
    color: $color-grays-silver; font: inherit; font-size: 14px; font-weight: 400; position: absolute; right: 0;
    &-error {color: $color-status-error;}
  }
}
