@keyframes reset {
  100% {transform: none; opacity: 1;}
}

@keyframes reset-transform {
  100% {transform: none;}
}

@keyframes appearing {
  100% {opacity: 1;}
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  20% { transform: rotate(5deg); }
  40% { transform: rotate(-5deg); }
  60% { transform: rotate(2deg); }
  80% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
}

@keyframes flare-light {
  10%, 100% { transform: translateX(100%) skewX(-45deg); }
}

@keyframes flying {
  50% {transform: scale(1) rotate(5deg);}
  100% {transform: scale(0.9) rotate(-5deg);}
}

.fadeonscroll {opacity: 0; transition-property: opacity, transform; transition-duration: 1s;
  will-change: opacity, transform;
  &-bottom {transform-origin: 50% 100%; --translateY: 100px; transform: translateY(var(--translateY));}
  &-bottom-growing {transform-origin: 50% 100%; --translateY: 100px; transform: scale(0) translateY(var(--translateY));}
  &-bottom-shrinking {transform-origin: 50% 100%; --translateY: 100px; transform: scale(2) translateY(var(--translateY));}
  &-left {transform-origin: 0% 50%; transform: translateX(-100px);}
  &-active {opacity: 1; transform: none;}
}

.fliponscroll {opacity: 0; transition-property: opacity, transform; transition-duration: 0.6s;
  transform-origin: center center; transform: rotateX(90deg);
  will-change: opacity, transform;
  &-active {opacity: 1; transform: none;}
}

.shake {animation: shake 0.8s; will-change: transform;}

.flare {position: relative; overflow: hidden;
  &::after {content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.3) 3em, rgba(0, 0, 0, 0) 6em);
    animation: flare-light 6s infinite; transform: translateX(-4rem) skewX(-45deg);
  }
}

.flying {animation: flying 10s linear infinite; transform: scale(0.9) rotate(-5deg); will-change: transform;}
