@import 'fonts';
@import 'variables';
@import 'animations';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import 'grid';
@import 'intl-tel-input';
@import 'mat-datepicker';
@import 'ngneat-dialog';
@import 'auth';
@import 'promo';
@import '~swiper/swiper.min.css';
@import 'toast';
@import 'pac';
@import 'skeleton';
@import 'image-placeholders';
@import 'form';
@import 'swiper';
@import 'dropdown';

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {pointer-events: none; opacity: 0.8; position: relative; transition: all 0.3s;
  &::after {position: absolute; width: 20px; height: 20px; border: $color-grays-white 5px solid; border-top-color: transparent;
    animation: spin 1s linear infinite; content: ""; top: 0; left: 0; bottom: 0; right: 0; margin: auto; border-radius: 50%;
    pointer-events: none;
  }
  &-primary::after {border-color: $color-brand-primary; border-top-color: transparent;}
  &-error::after {border-color: $color-status-error; border-top-color: transparent;}
  &-thin::after {border-width: 2px;}
  &-right::after {margin-right: 1.25rem;}
  &-clickable {pointer-events: all;}
}

html {font-family: $font; font-size: $font-size-body; line-height: 1.4em; font-weight: 400;
  --navbar-height: #{$navbar-height}; -webkit-tap-highlight-color: transparent;
  @media (max-width: $mobile) {--navbar-height: #{$navbar-height-mobile}; font-size: 13px;}
}

html, body {padding: 0; margin: 0; min-height: 100%; height: 100%; font-size-adjust: 0.58}

body {overflow-x: hidden;}

h1, h2, h3, h4, h5, h6 {margin: 0; padding: 0;}
h1 {@include h1;}
h2 {@include h2;}
h3 {@include h3;}
h4 {@include h4;}
h5 {@include h5;}
h6 {@include h6;}

p {margin: 0;}
a {text-decoration: none; cursor: pointer; color: inherit;}
button {padding: 0; margin: 0; background: none; outline: none; border: none; font: inherit; cursor: pointer;}

ul {list-style: none; margin: 0; padding: 0; padding-left: 1.5rem;
  li {margin-bottom: 0.75rem; position: relative;
    .bullet {position: absolute; background-color: $color-brand-secondary; text-align: left; left: -1.25rem; top: 0.4em;
      transform: rotate(30deg) skewX(-30deg) scale(1,.866);
      &:before, &:after {content: ''; position: absolute; background-color: inherit;}
      &, &:before, &:after {width:  0.5em; height: 0.5em; border-top-right-radius: 30%;}
      &:before {transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);}
      &:after {transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);}
      &-purple {background-color: $color-purple;}
    }
  }
}

.section {margin-bottom: 2rem;}

.hidden-scroll {-ms-overflow-style: none; scrollbar-width: none;
  &::-webkit-scrollbar {display: none;}
}

.property {display: flex; margin-bottom: 1.25rem; flex-wrap: wrap;
  &-name {width: 66.66%; flex: 0 0 66.66%; overflow: hidden; position: relative; padding-right: 0.5rem;
    box-sizing: border-box; line-height: 133%; color: $color-grays-silver; font-weight: 500;
  }
  &-value {width: 33.33%; flex: 0 0 33.33%; text-align: right;
    line-height: 133%; color: $color-grays-dark; font-weight: 500; align-self: center;
  }
}

.tooltip {background-color: $color-grays-dark !important; border-radius: 0.375rem; padding: 0.25rem 0.625rem;
  font-size: 0.75rem; font-weight: 400; line-height: 1.67em; text-align: left !important;
  &::after {display: none !important;}
}

.cdk-visually-hidden {visibility: hidden;}
.grecaptcha-badge { bottom: 74px !important; visibility: hidden !important;}

#toast-container > .custom-toast {
  background-color: $color-grays-white !important;
  border: 1px solid $color-purple;
  color: $color-grays-dark;
  border-left-width: 5px;
  padding-left: 8px;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  max-width: 290px;
}

#toast-container > .custom-toast .notification .icon {
  background-color: $color-purple !important;
}

@media (max-width: $mobile) {
  .hidden-mobile {display: none;}
}

@media (min-width: $mobile) {
  .visible-mobile {display: none;}
}


