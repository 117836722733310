.dropdown {position: relative; z-index: 1; display: inline-block;
  &-chevron-icon {position: absolute; top: 0; right: 12px; bottom: 0; width: 24px; height: 24px; margin: auto 0;
    transform: rotate(0); transition: all 0.4s;
  }
  &-content {position: absolute; top: 100%; left: 0; background: $color-grays-white;
    border-radius: 16px; box-shadow: $shadow-primary; opacity: 0;
    pointer-events: none; margin-top: 0; transition: all 0.4s;
    padding: 0.875rem 1.25rem; transform: scale(0.9, 0.7); transform-origin: 0 0;
    transition-timing-function: ease-out;
    &::before {position: absolute; content: ""; top: -16px; left: 18px;
      border: transparent 8px solid; border-bottom-color: $color-grays-white;
    }
    &-right {left: auto; right: 0;
      &::before {left: auto; right: 18px;}
    }
    &-scrollable {padding: 0;
      .dropdown-search {margin: 0;}
    }
  }
  &-search {margin: 0 -1.5rem; padding: 0 1.5rem 0.5rem;
    border-bottom: rgba($color-grays-black, 0.1) 1px solid;
  }
  &-item {display: block; padding: 0.375rem 0rem;
    color: $color-grays-black; box-sizing: border-box;
  }
  &-scroll {width: 100%; margin: 0 -50px;
    &-content {max-height: 350px; overflow: hidden; overflow-y: scroll;
      width: calc(100% + 100px); padding: 0 50px; box-sizing: border-box;
    }
    .dropdown-item {width: 100%;}
  }
  &-open {z-index: 10;
    .dropdown-content {margin-top: 10px; pointer-events: auto; opacity: 1;
      transform: scale(1,1); transition-duration: 0.4s;
      transition-timing-function: cubic-bezier(0,1.85,.32,1);
    }
    .dropdown-chevron-icon {transform: rotate(180deg);}
  }
}
