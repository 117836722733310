.mat {
  &-datepicker {
    &-content {background-color: $color-grays-white; box-shadow: $shadow-level-1 !important; border-radius: 16px !important;
      padding: 1rem !important; margin: 1rem 0;
      .actions {}
      .mat-calendar {height: auto !important;}
    }
    &-close-button {display: none !important;}
  }
  &-calendar {
    &-table {
      &-header {font-size: 14px; color: $color-grays-silver; line-height: 1.57em;
        th {font-weight: 400 !important;}
        .cdk-visually-hidden {display: none;}
      }
    }
    &-content {padding: 0 !important;}
    &-header {padding: 0 !important;}
    &-controls {margin: 0 0 2rem !important; padding: 0 10px;}
    &-body {
      &-label {visibility: hidden; padding: 0 !important;}
      &-cell {
        &-content {padding: 4px 8px !important; border: none !important; border-radius: 6px !important; transition: all 0.3s;
          font-size: 14px; line-height: 1.57em !important; width: 75% !important; height: 75% !important;
          top: 12.5% !important; left: 12.5% !important;
          &:hover {background-color: rgba($color-brand-primary, 0.3);}
        }
        &:focus .mat-focus-indicator::before {content: none !important;}
      }
      &-disabled {
        .mat-calendar-body-cell-content {color: $color-grays-silver !important; background-color: transparent !important;}
      }
      &-selected {background-color: $color-brand-primary; color: $color-grays-white;}
    }
    &-period-button {padding: 0 !important; background: none !important; font: inherit; font-size: 14px;
      line-height: 1.57em !important; transition: all 0.3s;
      &:hover {color: $color-purple;
        .mat-calendar-arrow {background: $color-purple !important;}
      }
    }
    &-previous-button, &-next-button {
      &:hover::after {background: $color-purple;}
      &::after {margin: 0 !important; transform: rotate(0) !important; border: none !important;
        width: 24px; height: 24px; background: transparent url(/assets/images/icons_v1.svg) no-repeat; transition: all 0.4s;
        mask: url(/assets/images/icons_v1.svg) no-repeat; background: $color-grays-silver;
      }
    }
    &-previous-button::after {background-position: -144px -24px; mask-position: -144px -24px;}
    &-next-button {margin-left: 8px !important;
      &::after {background-position: -144px 0px; mask-position: -144px 0px;}
    }
    &-arrow {border: none !important; width: 24px !important; height: 24px !important; margin: 0 !important;
      background: transparent url(/assets/images/icons_v1.svg) no-repeat; transition: all 0.4s;
      mask: url(/assets/images/icons_v1.svg) no-repeat; background: $color-grays-dark; mask-position: -144px -48px;
    }
  }
  &-icon-button {background: none !important; width: 24px !important; height: 24px !important; padding: 0 !important;}
  @media (max-width: $mobile) {
    &-calendar {width: 260px !important;}
  }
}
