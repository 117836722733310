.promo {font-size: $font-size-promo-default; color: $color-grays-black; padding: 0 $pagepadding; overflow: hidden;
  white-space: pre-line;
  &-section {margin-bottom: 6rem; position: relative;
    h2 {text-align: center; margin-bottom: 2.5rem; color: $color-grays-black;}
    &-emphasized {padding: 5rem $pagepadding; color: $color-grays-white; margin-left: -$pagepadding; margin-right: -$pagepadding;
      overflow: hidden;
      h2 {margin-bottom: 1.25rem; text-align: initial; color: $color-grays-white;}
      p {margin-bottom: 1.5rem; font-size: 1rem;}
    }
    &-form {color: $color-pink; padding: 5rem $pagepadding; margin-left: -$pagepadding; margin-right: -$pagepadding;
      overflow: hidden; margin-bottom: 0;
      &::before {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: $color-purple; z-index: -2;}
      h2 {margin-bottom: 1.75rem; margin-top: 0.75rem; text-align: initial; color: inherit;}
      p  {white-space: pre-line; max-width: 420px; line-height: 1.9em; margin-bottom: 1rem;}
    }
    &-more {margin-top: 1.25rem; max-width: 180px; width: 100%; display: inline-block;}
    &-background {position: absolute; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%;
      object-fit: cover; z-index: -1; background-size: cover; line-height: 0;
    }
  }
  &-top {
    h1 {margin-bottom: 0.45em;}
    h2 {margin-bottom: 1em;}
    p {font-size: 1.5rem; font-weight: 500; line-height: 1.5em; margin-bottom: 1.33em;}
  }
  &-form {
    &-label {margin-bottom: 0.75rem; font-size: 1rem;}
    &-section {margin-bottom: 0.625rem;}
    .row {margin: 0 -12px; margin-bottom: 0.25rem;}
    .col {padding: 0 12px;}
    &-submit {margin-top: 0.75rem;}
    &-button {opacity: 0.6;}
    &-circles {position: absolute; bottom: 0; left: 30px; z-index: -1; width: 300px; height: auto;}
  }
  &-image {max-width: 100%; width: 100%; object-fit: cover; border-radius: 24px; height: auto;}
  .content-smallest {
    h2 {text-align: initial; margin-bottom: 1.25rem;
      & + h2 {margin-top: -1.25rem;}
    }
    h3 {margin-bottom: 0.625rem;
      &:not(:first-child) {margin-top: 1.25rem;}
    }
    h4 {margin-bottom: 0.625rem;}
    p {margin-bottom: 0.625rem; font-size: $font-size-small;}
    a {color: $color-purple; transition: color 0.4s; font-weight: 500;
      &:hover {color: $color-purple-light;}
    }
    img {max-width: 100%;}
  }
  @media (max-width: $mobile) {font-size: 12px;
    h1 {font-size: $font-size-h3; font-weight: 600;}
    h3 {font-size: $font-size-body;}
    &-section {margin-bottom: 2rem;
      h2 {text-align: start; font-size: 1.23rem; margin-bottom: 4px;}
      &-more {max-width: 128px;}
      &-emphasized {padding: 24px $pagepadding;}
      &-form {padding-top: 16px; margin-bottom: 0;
        p {font-size: 1rem; line-height: 1.7em; max-width: 100%;}
        &.collapsed {padding-bottom: 10px;
          .promo-form-body {max-height: 0 !important;}
        }
      }
      &-big {
        h2 {font-size: $font-size-h3; margin-bottom: 16px;}
      }
    }
    &-top {
      h2 {font-size: 1.16rem; font-weight: 500;}
      p {font-size: 1.16rem;}
    }
    .content-smallest {
      h2 {font-size: 15px;}
      h3 {font-size: 18px;
        &:not(:first-child) {margin-top: 1.25rem;}
      }
      h4 {font-size: 11px;}
    }
    &-form {
      &-label {margin-bottom: 4px;}
      &-circles {left: 0; width: 120px;}
      &-section {margin-bottom: 0;}
      &-body {transition: max-height 0.8s cubic-bezier(0.165, 0.84, 0.44, 1); overflow: hidden;}
    }
  }
}
