.row {--col-padding: 8px; margin: 0 calc(-1 * var(--col-padding)); display: flex; flex-wrap: wrap;
  &-centered {align-items: center;}
  &-big {--col-padding: 16px;}
  &-large {--col-padding: 32px;}
  @media (max-width: $mobile) {
    &-big {--col-padding: 8px;}
  }
}
.col {padding: 0 var(--col-padding); box-sizing: border-box;
  &-content {width: auto; flex: 0 0 auto;}
  &-stretch {width: auto; flex: 1 1 0;}
  &-15 {width: 15%; flex: 0 0 15%;}
  &-20 {width: 20%; flex: 0 0 20%;}
  &-25 {width: 25%; flex: 0 0 25%;}
  &-30 {width: 30%; flex: 0 0 30%;}
  &-33 {width: 33.333333%; flex: 0 0 33.333333%;}
  &-34 {width: 34%; flex: 0 0 34%;}
  &-35 {width: 35%; flex: 0 0 35%;}
  &-40 {width: 40%; flex: 0 0 40%;}
  &-42 {width: 42%; flex: 0 0 42%;}
  &-44 {width: 44%; flex: 0 0 44%;}
  &-48 {width: 48%; flex: 0 0 48%;}
  &-50 {width: 50%; flex: 0 0 50%;}
  &-52 {width: 52%; flex: 0 0 52%;}
  &-56 {width: 56%; flex: 0 0 56%;}
  &-58 {width: 58%; flex: 0 0 58%;}
  &-60 {width: 60%; flex: 0 0 60%;}
  &-65 {width: 65%; flex: 0 0 65%;}
  &-66 {width: 66.666666%; flex: 0 0 66.666666%;}
  &-67 {width: 67%; flex: 0 0 67%;}
  &-70 {width: 70%; flex: 0 0 70%;}
  &-75 {width: 75%; flex: 0 0 75%;}
  &-80 {width: 80%; flex: 0 0 80%;}
  &-100 {width: 100%; flex: 0 0 100%;}
  @media (max-width: $mobile) {
    &-mobile-content {width: auto; flex: 0 0 auto;}
    &-mobile-stretch {width: auto; flex: 1 1 0;}
    &-mobile-35 {width: 35%; flex: 0 0 35%;}
    &-mobile-50 {width: 50%; flex: 0 0 50%;}
    &-mobile-75 {width: 75%; flex: 0 0 75%;}
    &-mobile-90 {width: 90%; flex: 0 0 90%;}
    &-mobile-100 {width: 100%; flex: 0 0 100%;}
  }
}
