.iti {display: block;
  &__selected {
    &-dial-code {font-size: 14px; line-height: $icon-size; color: $color-grays-black; padding-left: unset; margin-left: 8px}
  }
  &__arrow {display: none;}
  &__selected-flag {background: none !important; padding-left: 8px; padding-right: 62px}
  &__flag {
    border-radius: 3px; min-width: 20px; zoom: 1.3;
    &-container {border-radius: 16px; border: 1px solid $color-grays-border; position: absolute; width: 110px; background: $color-grays-white}
  }
  &__dial-code {color: $color-grays-silver; transition: color 0.3s; font-size: $font-size-promo-min;}
  &__country-list {z-index: 5; box-shadow: $shadow-level-1; border-color: $color-grays-border; border-radius: 16px 0 0 16px;
    margin-top: 4px; transition-property: opacity, transform; transition-duration: 0.4s; opacity: 1; transform: scale(1);
    transform-origin: 0 0; transition-timing-function: cubic-bezier(0,1.85,.32,1); overflow-x: hidden;
    &.iti__hide {display: block; opacity: 0; pointer-events: none; transform: scale(0.9, 0.7);
      transition-timing-function: ease-out; transition-duration: 0.2s;
    }
  }
  &__country {transition: all 0.3s; margin-bottom: 0; padding: 10px 16px; width: 321px;
    &.iti__highlight {background-color: rgba($color-pink, 0.2);
      .iti__dial-code {color: $color-purple; font-size: $font-size-promo-min;}
    }
  }
  &__country-name {color: $color-grays-black; font-size: $font-size-promo-min; margin-left: 10px; white-space: normal;}
  &__divider {padding: 0; margin: 0; border-color: $color-grays-border;}
}
