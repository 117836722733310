@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Medium.eot');
  src: url('../assets/fonts/poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/poppins/Poppins-Medium.woff2') format('woff2'),
      url('../assets/fonts/poppins/Poppins-Medium.woff') format('woff'),
      url('../assets/fonts/poppins/Poppins-Medium.ttf') format('truetype'),
      url('../assets/fonts/poppins/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Regular.eot');
  src: url('../assets/fonts/poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/poppins/Poppins-Regular.woff2') format('woff2'),
      url('../assets/fonts/poppins/Poppins-Regular.woff') format('woff'),
      url('../assets/fonts/poppins/Poppins-Regular.ttf') format('truetype'),
      url('../assets/fonts/poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-SemiBold.eot');
  src: url('../assets/fonts/poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/poppins/Poppins-SemiBold.woff') format('woff'),
      url('../assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype'),
      url('../assets/fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Bold.eot');
  src: url('../assets/fonts/poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/poppins/Poppins-Bold.woff2') format('woff2'),
      url('../assets/fonts/poppins/Poppins-Bold.woff') format('woff'),
      url('../assets/fonts/poppins/Poppins-Bold.ttf') format('truetype'),
      url('../assets/fonts/poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: optional;
}

